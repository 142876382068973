import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import OverwolfGoVipAccountLevel from "@/pages/overwolf/components/OverwolfGoVipAccountLevel";
import OverwolfGoVipRewards from "@/pages/overwolf/components/OverwolfGoVipRewards";
import FrameButton from "@/components/buttons/FrameButton";
import {useEffect, useState} from "react";
import MagicLoginModal from "@/components/modals/MagicLoginModal";
import {toggleSidebar} from "@/redux/meta/metaActions";
import OverwolfIndustryPassPromoBanner from "@/pages/overwolf/components/OverwolfIndustryPassPromoBanner";
import useGetOverwolfArt, {OverwolfArtType} from "@/hooks/useGetOverwolfArt";
import OverwolfGameLogo from "./components/OverwolfGameLogo";
import {ThemePreference} from "@/types/Theme";
import useThemePreference from "@/hooks/useThemePreference";
import {store} from "@/redux";
import OverwolfDiscordBanner from "@/pages/overwolf/components/OverwolfDiscordBanner";

export default function OverwolfGoVip() {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const { getOverwolfArt} = useGetOverwolfArt(OverwolfArtType.bannerImage);
    const dispatch = useDispatch();
    const {setTheme} = useThemePreference();

    // Close the sidebar menu if open
    useEffect(() => {
        dispatch(toggleSidebar(false));
        const originalTheme = store.getState().metaStore.themePreference;
        setTheme(ThemePreference.DARK);

        return () => {
            if (originalTheme) {
                setTheme(originalTheme);
            }
        };
    }, []);

    return (
        <>
            <div
                className="overwolf-govip"
                style={{
                    backgroundImage: `url(${getOverwolfArt})`,
                }}
            >
                <MagicLoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)}/>

                <div className="overwolf-govip_content">
                    <div className="overwolf-govip_header">
                        <OverwolfGameLogo/>
                        <h1>PLAY TO EARN</h1>
                    </div>

                    <OverwolfGoVipAccountLevel/>

                    <OverwolfIndustryPassPromoBanner/>

                    <OverwolfGoVipRewards/>
                </div>

                {!fullToken && <FrameButton
                    size="large"
                    className="overwolf-govip_login-btn overwolf-govip_button_purple-blue-gradient-shadow"
                    onClick={() => setIsLoginModalOpen(true)}
                >
                    Login or Sign up
                </FrameButton>
                }
            </div>
            {fullToken && <OverwolfDiscordBanner />}
        </>

    );
}
