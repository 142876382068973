import FrameButton from "@/components/buttons/FrameButton";
import MagicLoginModal from "@/components/modals/MagicLoginModal";
import useGetOverwolfArt, { OverwolfArtType } from "@/hooks/useGetOverwolfArt";
import useThemePreference from "@/hooks/useThemePreference";
import { store } from "@/redux";
import { IStore } from "@/redux/defaultStore";
import { toggleSidebar } from "@/redux/meta/metaActions";
import { ThemePreference } from "@/types/Theme";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import OverwolfDiscordBanner from "./components/OverwolfDiscordBanner";
import OverwolfGameLogo from "./components/OverwolfGameLogo";
import OverwolfGoVipAccountLevel from "./components/OverwolfGoVipAccountLevel";
import { FaExternalLinkAlt } from "react-icons/fa";
import { isDesktop } from "react-device-detect";

export default function OverwolfDiscoverPage() {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const { getOverwolfArt } = useGetOverwolfArt(OverwolfArtType.bannerImage);
    const dispatch = useDispatch();
    const { setTheme } = useThemePreference();

    // Close the sidebar menu if open
    useEffect(() => {
        dispatch(toggleSidebar(false));
        const originalTheme = store.getState().metaStore.themePreference;
        setTheme(ThemePreference.DARK);

        return () => {
            if (originalTheme) {
                setTheme(originalTheme);
            }
        };
    }, []);

    return (
        <>
            <div
                className="overwolf-discover-page"
                style={{
                    backgroundImage: `url(${getOverwolfArt})`,
                }}
            >
                <MagicLoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />

                <div className="overwolf-discover-page_content">
                    <div className="overwolf-discover-page_header">
                        <OverwolfGameLogo />
                        <h1>HOW TO START</h1>
                    </div>

                    <OverwolfGoVipAccountLevel />

                    <div className="overwolf-govip_rewards_time_empty">
                        <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/govip-ow-time-rewards-modal-empty.webp`}
                            alt="Overwolf Time Rewards Empty"
                        />
                        <h5>Coming Soon</h5>
                    </div>
                    <div className="overwolf-discover-page_footer">

                        <div className="overwolf-survey_divider" />

                        <div className="overwolf-survey">
                            <a
                                href="https://share.hsforms.com/1pM-BLE3hR46pP34PIt5nqAnrwoo"
                                className="go-vip-ow-devourplay-banner_survey"
                                target="_blank"
                                rel="noreferrer"
                            >
                            Give Feedback <FaExternalLinkAlt />
                            </a>
                            <div className="overwolf-survey_text">
                            We're excited to bring you the initial version of our app and would love your feedback to
                            make it even better.
                            </div>
                        </div>
                    </div>
                </div>

                {!fullToken &&
                    <FrameButton
                        size="large"
                        className="overwolf-discover-page_login-btn overwolf-discover-page_button_purple-blue-gradient-shadow"
                        onClick={() => setIsLoginModalOpen(true)}
                    >
                        Login or Sign up
                    </FrameButton>
                }
            </div>
        </>
    );
}
