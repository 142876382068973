import {Dispatch, PropsWithChildren, ReactElement, SetStateAction, useContext, useEffect, useState} from "react";
import {
    ValidateDeliveryDistanceResponse,
} from "@devour/client";
import RestaurantMenusSearch from "./RestaurantMenusSearch";
import RestaurantMenusBrowse from "./RestaurantMenusBrowse";
import {RestaurantContext} from "../context/RestaurantContext";
import RestaurantMenuSkeleton from "../../../components/skeletons/restaurantMenuPage/RestaurantMenuSkeleton";
import {useRestaurantMenu} from "@/hooks/useRestaurantMenu";
import RestaurantMenusPreload from "@/pages/restaurants/components/RestaurantMenusPreload";
import {useRestaurant} from "@/hooks/useRestaurant";
import RestaurantHeaderWithHandoffOptions from "@/components/restaurants/RestaurantHeaderWithHandoffOptions";
import Divider from "@/components/Divider";
import {isRestaurantAvailable, useRestaurantAvailability} from "@/hooks/useRestaurantAvailability";
import MenuUnavailableModal from "@/pages/restaurants/components/MenuUnavailableModal";
import {useQueryClient} from "@tanstack/react-query";

interface Props {
    validateDeliveryDistanceResponse?: ValidateDeliveryDistanceResponse;
    setMobileSearchMode: Dispatch<SetStateAction<boolean>>;
    mobileSearchMode: boolean;
    placeId: string;
    toggleShowCartPanel?: () => void;
}

function RestaurantMenus(props: PropsWithChildren<Props>): ReactElement {
    const {restaurantId} = useContext(RestaurantContext);
    const {data: restaurant} = useRestaurant(restaurantId, props.placeId);
    const isDevourIQMenuExpired = restaurant && restaurant.isDevourIQ && !restaurant.isDevourIQMenuFresh;

    const queryRestaurantMenu = useRestaurantMenu(restaurantId);
    const queryExpiredDevourIQMenu = useRestaurantMenu(restaurantId, isDevourIQMenuExpired);
    const {data: restaurantAvailability} = useRestaurantAvailability(restaurant);
    const availabilityCheckFailed = !isRestaurantAvailable(restaurantAvailability);
    const [
        showMenuUnavailableModal,
        setShowMenuUnavailableModal,
    ] = useState<boolean>(false);

    const queryRestaurant = useRestaurant(restaurantId, props.placeId);

    const queryClient = useQueryClient();

    const handleInvalidateQueries = () => {
        queryClient.invalidateQueries({
            queryKey: ["user-get-restaurants"],
        });
    };

    useEffect(() => {
        if (availabilityCheckFailed) {
            handleInvalidateQueries();
            setShowMenuUnavailableModal(true);
        }
    }, [availabilityCheckFailed]);

    if (!restaurant || !restaurant.menus?.length && !queryRestaurantMenu.data && !queryExpiredDevourIQMenu.data) {
        return <RestaurantMenuSkeleton/>;
    } if (!queryRestaurantMenu.data && !queryExpiredDevourIQMenu.data) {
        return (
            <>
                <div className="restaurant-page_section-margin">
                    <RestaurantHeaderWithHandoffOptions
                        data={queryRestaurant.data}
                    />
                </div>
                <Divider/>
                <div className="restaurant-page_section-margin">
                    <RestaurantMenusPreload/>
                </div>
            </>
        );

    } if (props.mobileSearchMode) {
        return (
            <div className="restaurant-page_section-margin">
                <RestaurantMenusSearch
                    setMobileSearchMode={props.setMobileSearchMode}
                    restaurantMenu={queryRestaurantMenu.data || queryExpiredDevourIQMenu.data}
                />
            </div>
        );
    }
    return (
        <>
            <MenuUnavailableModal
                display={showMenuUnavailableModal}
                onToggle={() => setShowMenuUnavailableModal(!showMenuUnavailableModal)}
            />
            <div className="restaurant-page_section-margin">
                <RestaurantHeaderWithHandoffOptions
                    data={queryRestaurant.data}
                >
                    {props.children}
                </RestaurantHeaderWithHandoffOptions>
            </div>
            <Divider height={"0.25rem"}/>
            <RestaurantMenusBrowse
                validateDeliveryDistanceResponse={props.validateDeliveryDistanceResponse}
                setMobileSearchMode={props.setMobileSearchMode}
                restaurantMenu={queryRestaurantMenu.data || queryExpiredDevourIQMenu.data}
                toggleShowCartPanel={props.toggleShowCartPanel}
            />
        </>
    );

}

export default RestaurantMenus;
