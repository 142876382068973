import React, {useMemo, CSSProperties, Dispatch, ReactElement, SetStateAction, useState} from "react";
import {OverwolfApi, OWReward, OWUserStat, OWRewardMilestone} from "@devour/client";
import classNames from "classnames";
import {RiVipDiamondLine} from "react-icons/ri";
import CircularProgressIndicator from "../CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameButton from "../buttons/FrameButton";
import {MdCheck, MdLock} from "react-icons/md";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import getConfig from "@/utils/getConfig";
import useGetOverwolfUserStats from "@/hooks/useGetOverwolfUserStats";
import Toast from "@/components/Toast";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useGetUserLevel} from "@/hooks/useGetUserLevel";
import {store} from "@/redux";
import {updateAccountLevel, updateCurrentUser} from "@/redux/meta/metaActions";
import {BsTriangleFill} from "react-icons/bs";
import AnimateHeight from "react-animate-height";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import Spacer from "@/components/Spacer";
import {findLastIndex} from "lodash";

interface Props {
    quest: OWReward;
    userStat?: OWUserStat;
    className?: string;
    gameId: string;
    isClaimingInProgress: boolean;
    setIsClaimingInProgress: Dispatch<SetStateAction<boolean>>;
}

export default function GoVipOWQuestRewardCard(props: Props) {
    const {quest, userStat} = props;
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [claimingToast, setClaimingToast] = useState<{ message: string, success: boolean }>(undefined);
    const [isQuestCollapsed, setIsQuestCollapsed] = useState<boolean>(true);
    const {refetch: refetchUserStats} = useGetOverwolfUserStats(fullToken, props.gameId, false);
    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken, true);
    const {refetch: refetchUserLevel} = useGetUserLevel(fullToken, store.getState().metaStore.currentUser?.user.id, true);
    const isOnMobile = isMobile && !isTablet;
    const {isOnOverwolf} = useOverwolfInterop();
    const numOfMilestones = quest.milestones?.length || 0;
    const userProgress = userStat?.progress || 0;

    const lastMilestoneCompletedIndex = useMemo(
        () => findLastIndex(quest.milestones ?? [], m => m.target <= userProgress),
        [quest.id, userProgress],
    );

    async function claimOverwolfReward(target?: number) {
        props.setIsClaimingInProgress(true);
        try {
            const res = await new OverwolfApi(getConfig(fullToken)).claimOverwolfReward({
                rewardId: props.quest.id,
                claimedTarget: numOfMilestones > 0 ? target : undefined,
            });
            await refetchUserStats();
            setClaimingToast({
                message: `${res.rewardAmount} ${props.quest.rewardType} successfully claimed!`,
                success: true,
            });
            void updateUserLevel();
        } catch (e) {
            setClaimingToast({
                message: "There has been a problem claiming your reward. Please try again later!",
                success: false,
            });
        } finally {
            props.setIsClaimingInProgress(false);
        }
    }

    async function updateUserLevel() {
        const [
            updatedUserProfile,
            updatedUserLevel,
        ] = await Promise.all([refetchUserProfile(), refetchUserLevel()]);
        if (updatedUserProfile.data) {
            dispatch(updateCurrentUser(updatedUserProfile.data));
        }

        if (updatedUserLevel.data) {
            dispatch(updateAccountLevel(updatedUserLevel.data));
        }
    }

    const getCurrentProgress = () => {
        if (!props.userStat) {
            return 0;
        }
        if (props.userStat.progress < props.quest.target) {
            return props.userStat.progress;
        }
        return props.quest.target;
    };

    const renderClaimButton = (target: number, isClaimed?: boolean) => {
        if (!fullToken || !userStat || userStat.progress < target) {
            return null;
        }

        if (userStat?.isClaimed || isClaimed) {
            return (
                <FrameButton
                    size="narrow"
                    className="go-vip-ow_quest-reward-card_claimed"
                >
                    Claimed {(isOnOverwolf || isDesktop) && <MdCheck/>}
                </FrameButton>
            );
        }

        return (
            <FrameButton
                size="narrow"
                onClick={() => claimOverwolfReward(target)}
                color="devourplay-gradient"
                className="go-vip-ow_quest-reward-card_claim-btn"
                forwardProps={{disabled: props.isClaimingInProgress}}
            >
                Claim
            </FrameButton>
        );
    };

    const getCircularProgressGlow = () => {
        return props.userStat && props.userStat.progress >= props.quest.target && !props.userStat.isClaimed;
    };

    const renderCompletedAt = (top?: boolean) => {
        if (!props.userStat || !props.userStat.isClaimed) {
            return null;
        }

        const completedDate: string = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "numeric",
        }).format(props.userStat.updatedAt);

        return <div className={`go-vip-ow_quest-reward-card_completed-at ${top ? "top" : ""}`}>
            Completed on {completedDate}
        </div>;
    };

    function renderIcon(): ReactElement {
        if (fullToken && numOfMilestones) {
            return <>
                <Spacer />
                <BsTriangleFill
                    className={classNames(
                        "go-vip-ow_quest-reward-card_info_icon",
                        "go-vip-ow_quest_milestones_collapse-icon", {
                            "go-vip-ow_quest_milestones_collapse-icon_flipped": !isQuestCollapsed,
                        },
                    )}
                    onClick={() => setIsQuestCollapsed(!isQuestCollapsed)}
                />
            </>;
        }

        if (!fullToken) {
            return <>
                <Spacer />
                <MdLock
                    size={"1.5rem"}
                    className="go-vip-ow_quest-reward-card_info_icon go-vip-ow_quest-reward-card_lock"
                />
            </>;
        }
    }

    function getToastStyle(): CSSProperties {
        if (isOnMobile && isOnOverwolf) {
            return {
                maxWidth: "100vw",
                width: "100vw",
                borderRadius: "0",
                marginBottom: "0",
                marginLeft: "0",
            };
        }

        return {
            marginBottom: "0",
        };
    }

    return (
        <>
            <Toast
                message={claimingToast?.message}
                isOpen={!!claimingToast}
                onDismiss={() => setClaimingToast(undefined)}
                removeMarginAdjustment={true}
                className={classNames(
                    "overwolf-govip_rewards_claim",
                    {"overwolf-govip_rewards_claim_in-game": isMobile && isOnOverwolf},
                )}
                forceDarkMode={true}
                variant={claimingToast?.success ? "success" : "error"}
                style={isOnOverwolf && getToastStyle()}
            />
            <div
                className={classNames("go-vip-ow_quest-reward-card", {
                    "disabled": !fullToken,
                })}>
                {(!isOnOverwolf || isOnMobile) && renderCompletedAt(true)}

                <div className="go-vip-ow_quest-reward-card_main">
                    <div className="go-vip-ow_quest-reward-card_info">
                        <CircularProgressIndicator
                            showGlow={getCircularProgressGlow()}
                            total={props.quest.target}
                            current={getCurrentProgress()}
                        />
                        <div className="go-vip-ow_quest-reward-card_info_content">
                            <strong className="go-vip-ow_quest-reward-card_info_content_title">
                                {props.quest.name} {!isOnMobile && isOnOverwolf && renderCompletedAt()}
                            </strong>
                            <p className="go-vip-ow_quest-reward-card_info_content_description">
                                {props.quest.description}
                            </p>

                            <strong className={classNames(
                                "go-vip-ow_quest-reward-card_info_content_points",
                                {"no-user-stats": !props.userStat},
                            )}
                            >
                                <RiVipDiamondLine className={!props.userStat && "no-user-stats"}/>
                                {props.quest.rewardAmount} {props.quest.rewardType}
                            </strong>
                        </div>

                        {renderIcon()}
                    </div>

                    {fullToken && !numOfMilestones && <div className="go-vip-ow_quest-reward-card_action">
                        {renderClaimButton(quest.target, props.userStat?.isClaimed)}
                    </div>}
                </div>

                {numOfMilestones > 0 &&
                    <AnimateHeight
                        duration={300}
                        height={isQuestCollapsed ? 0 : "auto"}
                    >
                        <div className="go-vip-ow_quest_milestones">
                            <div className="go-vip-ow_quest_milestones_progress">
                                {Array.from({length: numOfMilestones}, (_, i) =>
                                    <div
                                        className={classNames("go-vip-ow_quest_milestones_progress_marker", {
                                            "completed": userStat?.progress >= quest.milestones[i]?.target,
                                        })}
                                        key={`${quest.id}-marker-${i}`}
                                    />)}
                                <div
                                    className="go-vip-ow_quest_milestones_progress_bar"
                                    style={{ height: `${lastMilestoneCompletedIndex / (numOfMilestones - 1) * 100}%` }}
                                />
                            </div>
                            <div className="go-vip-ow_quest_milestones_list">
                                {quest.milestones.map((milestone: OWRewardMilestone, index) =>
                                    <React.Fragment key={`${quest.id}-${milestone.target}`}>
                                        <div
                                            key={`${quest.id}-${milestone.target}`}
                                            className={`go-vip-ow_quest_milestones_milestone ${quest.id}-${milestone.target}`}
                                        >
                                            <div>
                                                <p>{milestone.name}</p>
                                                <strong
                                                    className="go-vip-ow_quest_milestones_milestone_points go-vip-ow_quest-reward-card_info_content_points">
                                                    <RiVipDiamondLine/> {milestone.amount} {props.quest.rewardType}
                                                </strong>
                                            </div>
                                            {renderClaimButton(milestone.target, !!userStat?.claimLogs?.find(log => log.target === milestone.target))}
                                        </div>
                                        {index !== numOfMilestones - 1 &&
                                            <div className="go-vip-ow_quest_milestones_divider"/>}
                                    </React.Fragment>)}
                            </div>
                        </div>
                    </AnimateHeight>
                }
            </div>
        </>
    );
}
