import { GetOverwolfXpSubjectEnum, OverwolfApi } from "@devour/client";
import useGetUserOverwolfXP from "@/hooks/useGetUserOverwolfXP";
import { useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import Skeleton from "react-loading-skeleton";
import Tooltip from "@/components/Tooltip";
import { BsFillInfoCircleFill, BsFillQuestionCircleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { capitalizeFirstLetter } from "@/utils/formatString/capitalizeFirstLetter";
import { IoClose } from "react-icons/io5";
import getConfig from "@/utils/getConfig";
import FrameOneSwitchInput from "@/components/inputs/FrameOneSwitchInput";
import OverwolfGoVipTimeRewards from "@/pages/overwolf/components/OverwolfGoVipTimeRewards";
import useGetOverwolfGameInfo from "@/hooks/useGetOverwolfGameInfo";
import useOverwolfInterop, { overwolfGameInfo } from "@/hooks/useOverwolfInterop";
import { isDev } from "@/utils/isDev";
import useGetOverwolfGameRewards from "@/hooks/useGetOverwolfGameRewards";
import OverwolfGoVipQuestRewards from "./OverwolfGoVipQuestRewards";
import useGetOverwolfUserStats from "@/hooks/useGetOverwolfUserStats";
import { isDesktop } from "react-device-detect";
import useGetOverwolfGameEventsDisabled from "@/hooks/useGetOverwolfGameEventsDisabled";
import { MdWarning } from "react-icons/md";
import { FaExternalLinkAlt } from "react-icons/fa";

export enum RewardsView {
    QUESTS,
    TIME_PLAYED,
}

const testGame = {
    gameId: "21640",
    gameTitle: "Valorant",
    isGameRunning: true,
} as overwolfGameInfo;

export default function OverwolfGoVipRewards() {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { isOnOverwolf } = useOverwolfInterop();
    const owGameInfo = useSelector((store: IStore) => store.metaStore.overwolfGameInfo);
    const gameInfo = !isOnOverwolf && isDev ? testGame : owGameInfo;

    const [gameDetectionTag, setGameDetectionTag] = useState("Game not detected");
    const [isGameDetected, setIsGameDetected] = useState(false);
    const [rewardsView, setRewardsView] = useState<RewardsView>(RewardsView.TIME_PLAYED);
    const [showWeeklyLimitReached, setShowWeeklyLimitReached] = useState(false);
    const [questDisabledAlertDismissed, setQuestDisabledAlertDismissed] = useState(false);
    const { gameEventsDisabled } = useGetOverwolfGameEventsDisabled(gameInfo?.gameId);
    const { data: devourGameInfo } = useGetOverwolfGameInfo(gameInfo?.gameId);
    const { data: overwolfGameRewards, isLoading: isOverwolfGameRewardsLoading } = useGetOverwolfGameRewards(
        gameInfo?.gameId,
        devourGameInfo?.isEventSupported ?? false,
    );
    const {
        data: timeRewardsData,
        refetch: refetchTimeRewardsData,
        isLoading: isTimeRewardsDataInitialLoading,
    } = useGetUserOverwolfXP(fullToken, GetOverwolfXpSubjectEnum.TIMEREWARD);
    const {
        data: userStats,
        refetch: refetchUserStats,
    } = useGetOverwolfUserStats(fullToken, gameInfo?.gameId || devourGameInfo?.gameId);
    const shouldShowQuestEventWarning = devourGameInfo?.isEventSupported && gameEventsDisabled;
    useOverwolfInterop((ev) => {
        if (ev.type === "ow:game-events-logged") {
            void refetchUserStats();
        }
    });

    useEffect(() => {
        if (gameInfo) {
            setGameDetectionTag(gameInfo.gameTitle);
            setIsGameDetected(true);
        } else {
            setGameDetectionTag("Game not detected");
            setIsGameDetected(false);
            setRewardsView(RewardsView.TIME_PLAYED);
        }
    }, [gameInfo?.gameTitle]);

    useEffect(() => {
        /*
         * Show the weekly limit reached message if the user has not dismissed it
         * undefined means the user has not reached the limit yet
         */
        if (timeRewardsData?.isWeeklyLimitFeedbackDismissed === false) {
            setShowWeeklyLimitReached(true);
        }
    }, [timeRewardsData?.isWeeklyLimitFeedbackDismissed]);

    function getHeaderDescription() {
        if (devourGameInfo?.isEventSupported) {
            return "Complete quests to boost XP and earn 10 XP for every hour you play!";
        }

        if (!fullToken) {
            return "Earn 10 XP for every hour you play, with a max of 1000 XP each week!";
        }

        return `Earn 10 XP for every hour of gameplay! ${
            isDesktop ? "The more you play, the more XP you collect." : ""
        }`;
    }

    async function dismissWeeklyLimitFeedback() {
        setShowWeeklyLimitReached(false);
        try {
            await new OverwolfApi(getConfig(fullToken)).dismissWeeklyLimitFeedback();
            await refetchTimeRewardsData();
        } catch {}
    }

    const renderSkeleton = () =>
        <div className="overwolf-govip_rewards_time_list">
            {Array.from({ length: 5 }, (_, i) =>
                <div key={i} className="go-vip-ow_time-reward-card overwolf-card">
                    <div>
                        <p>
                            <Skeleton width={150} />
                        </p>
                        <span>
                            <Skeleton width={110} />
                        </span>
                    </div>
                    <span>
                        <Skeleton width={110} />
                    </span>
                </div>)}
        </div>;
    return (
        <div className="overwolf-govip_rewards">
            <div
                className={classNames("overwolf-govip_rewards_tag", {
                    "overwolf-govip_rewards_tag_active": isGameDetected && !shouldShowQuestEventWarning,
                    "overwolf-govip_rewards_tag_warning": isGameDetected && shouldShowQuestEventWarning,
                })}
            >
                <div
                    className={classNames("circle", {
                        circle_active: isGameDetected && !shouldShowQuestEventWarning,
                        circle_warning: isGameDetected && shouldShowQuestEventWarning,
                        circle_inactive: !isGameDetected,
                    })}
                />
                {isGameDetected && "In "} {capitalizeFirstLetter(gameDetectionTag)}
                {isGameDetected && shouldShowQuestEventWarning && " - Quests off"}
            </div>
            <div className="overwolf-govip_rewards_header">
                <div>
                    <div className="overwolf-govip_rewards_header_top">
                        <h4>Track Rewards</h4>
                        <Tooltip
                            className="overwolf-govip_rewards_header_tooltip"
                            outsideIcon={BsFillQuestionCircleFill}
                            containerElementClassname="overwolf-govip_rewards"
                        >
                            <div className="overwolf-govip_rewards_header_tooltip_content">
                                Earn up to max 1000 XP each week! Your weekly XP limit resets every Monday at 12:00 AM
                                (EST).
                            </div>
                        </Tooltip>
                    </div>

                    <div className="overwolf-govip_rewards_header_description">{getHeaderDescription()}</div>
                </div>

                {devourGameInfo?.isEventSupported &&
                    <FrameOneSwitchInput<RewardsView>
                        className="overwolf-govip_rewards_header_switch"
                        name="overwolf-rewards-view-toggle"
                        value={rewardsView}
                        onToggle={setRewardsView}
                        options={[
                            {
                                render: "Quests",
                                value: RewardsView.QUESTS,
                            },
                            {
                                render: "Time Played",
                                value: RewardsView.TIME_PLAYED,
                            },
                        ]}
                    />
                }
            </div>

            {shouldShowQuestEventWarning &&
                rewardsView === RewardsView.QUESTS &&
                isGameDetected &&
                !questDisabledAlertDismissed &&
                    <div className="overwolf-govip_rewards_quest_disabled">
                        <div className="overwolf-govip_rewards_quest_disabled_left">
                            <MdWarning size="22px" />
                            <div>
                                {capitalizeFirstLetter(gameDetectionTag)} quest tracking is temporarily paused. Please
                                check back soon!
                            </div>
                        </div>
                        <button onClick={() => setQuestDisabledAlertDismissed(true)} className="reset-button">
                            <IoClose size="22px" />
                        </button>
                    </div>
            }

            {rewardsView === RewardsView.TIME_PLAYED && showWeeklyLimitReached &&
                <div className="overwolf-govip_rewards_time_limit-reached">
                    <div className="overwolf-govip_rewards_time_limit-reached_left">
                        <BsFillInfoCircleFill size="22px" />
                        <div>You have reached your weekly XP limit! It will reset every Monday at 12:00 AM (EST).</div>
                    </div>
                    <button onClick={dismissWeeklyLimitFeedback} className="reset-button">
                        <IoClose size="22px" />
                    </button>
                </div>
            }

            {rewardsView === RewardsView.TIME_PLAYED &&
                <>
                    {isTimeRewardsDataInitialLoading
                        ? renderSkeleton()
                        : <OverwolfGoVipTimeRewards rewards={timeRewardsData?.transactions || []} />
                    }
                </>
            }

            {rewardsView === RewardsView.QUESTS &&
                <>
                    {isOverwolfGameRewardsLoading
                        ? renderSkeleton()
                        : <OverwolfGoVipQuestRewards
                            userStats={userStats?.userStats || []}
                            gameId={gameInfo?.gameId}
                            quests={overwolfGameRewards || []}
                        />
                    }
                </>
            }

            <div className="overwolf-survey_divider" />

            <div className="overwolf-survey">
                <a
                    href="https://share.hsforms.com/1pM-BLE3hR46pP34PIt5nqAnrwoo"
                    className="go-vip-ow-devourplay-banner_survey"
                    target="_blank"
                    rel="noreferrer"
                >
                    Give Feedback <FaExternalLinkAlt />
                </a>
                <div className="overwolf-survey_text">
                    We're excited to bring you the initial version of our app and would love your feedback to make it
                    even better.
                </div>
            </div>
        </div>
    );
}
